import React from "react";
import Link from "next/link";

export default function Breadcrumb({ crumbs }) {
  return (
    crumbs && (
      <nav aria-label="Fil d'Ariane">
        <ol className="flex flex-wrap pb-4">
          {crumbs.map((crumb, index) => {
            const isFirst = index === 0;
            const isLast = index === crumbs.length - 1;
            return (
              <li key={index}>
                {!isFirst && (
                  <span
                    className="inline-block px-2 separator"
                    aria-hidden="true"
                  >
                    &gt;
                  </span>
                )}
                {!isLast && crumb.path ? (
                  <Link href={crumb.path} passHref>
                    <a className="text-sm font-medium tracking-wide hover:text-primary-lighter transition">
                      {crumb.title}
                    </a>
                  </Link>
                ) : (
                  <span className="text-sm font-medium tracking-wide">
                    {crumb.title}
                  </span>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    )
  );
}
