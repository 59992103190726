import React from "react";
import Breadcrumb from "components/Breadcrumb";

export default function Header({ crumbs, title }) {
  return (
    <div className="relative -mt-10 bg-white">
      <div
        className="relative bg-gradient-to-l from-primary-light to-primary py-12"
        style={{ height: " 500px" }}
      >
        <div className="absolute top-0 right-0 w-full h-full overflow-hidden z-0 opacity-30 bg-ocean-pattern bg-[length:600px] bg-center" />
      </div>
      <div className="relative">
        <div
          className="top-0.5 -translate-y-full bg-center absolute bg-ellipse-header-white bg-cover w-full"
          style={{ paddingBottom: "8%" }}
        />
        <section className="container mx-auto px-4 relative bg-white">
          <h1 className="mb-8 text-5xl uppercase block md:inline-block font-important after:h-1 after:w-16 after:bg-secondary after:md:inline-block after:block after:mt-2 after:md:ml-4">
            {title}
          </h1>
          <Breadcrumb crumbs={crumbs} />
        </section>
      </div>
    </div>
  );
}
