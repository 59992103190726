export default function useField(field) {
  const formId = field["#webform"];
  const id = field["#webform_id"];
  const name = field["#webform_key"];
  const disabled = field["#disabled"] || false;
  const required = field["#required"] || false;
  const requiredMessage = field["#required_error"] || "Le champ est requis.";
  const titleDisplay = field["#title_display"] || "before";
  const options = Object.prototype.hasOwnProperty.call(field, "#options")
    ? Object.keys(field["#options"]).map((key) => {
        return {
          key: field["#options"][key],
          value: key,
        };
      })
    : null;

  return {
    formId,
    id,
    name,
    disabled,
    required,
    requiredMessage,
    titleDisplay,
    options,
  };
}
