import React, { createRef, useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Text from "components/Form/Fields/Text";
import Textarea from "components/Form/Fields/Textarea";
import Select from "components/Form/Fields/Select";
import Checkbox from "components/Form/Fields/Checkbox";
import Checkboxes from "components/Form/Fields/Checkboxes";
import Radios from "components/Form/Fields/Radios";
import File from "components/Form/Fields/File";
import Captcha from "components/Form/Fields/Captcha";
import Button from "components/Button";
import classNames from "lib/classNames";
import Body from "components/Body";

export default function Form({ id, dataFields }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const [status, setStatus] = useState();
  const [message, setMessage] = useState("");
  const [required, setRequired] = useState(true); // TODO: use as callback for subcomponents
  const [fields, setFields] = useState([]);
  const [captcha, setCaptcha] = useState([]);
  const [files, setFiles] = useState([]);
  const formRef = createRef();
  const captchaRef = useRef();

  const components = {
    textfield: Text,
    email: Text,
    tel: Text,
    textarea: Textarea,
    select: Select,
    checkbox: Checkbox,
    checkboxes: Checkboxes,
    radios: Radios,
    managed_file: File,
  };

  useEffect(() => {
    // JSON to Array
    if (dataFields !== null) {
      const tmpFields = [];
      let tmpCaptcha = null;

      Object.keys(dataFields).forEach((key) => {
        const type = dataFields[key]["#type"];
        const name = dataFields[key]["#webform_key"];

        if (type && type === "hidden" && name && name === "captcha") {
          tmpCaptcha = dataFields[key];
        } else {
          tmpFields.push(dataFields[key]);
        }
      });

      if (tmpFields.length > 0) {
        setFields(tmpFields);
      }

      if (tmpCaptcha !== null) {
        setCaptcha(tmpCaptcha);
      }
    }
  }, [dataFields]);

  useEffect(() => {
    if (status === "success") {
      formRef.current.reset();
      reset({});
    }
  }, [status]);

  function filesHandler(field, file) {
    setFiles([
      ...files,
      {
        field_name: field,
        value: file.fid[0].value,
      },
    ]);
  }

  async function onSubmit(data) {
    const originalData = data;

    // Prevent submit spam
    if (status === "submitting") {
      return undefined;
    }

    // Captcha token handling
    if (captcha && captchaRef.current) {
      const captchaToken = await captchaRef.current.executeAsync();

      if (captchaToken) {
        originalData[captcha["#webform_key"]] = captchaToken;
      } else {
        return undefined;
      }
    }

    setStatus("submitting");
    setMessage("");

    // Files handling
    Object.keys(files).forEach((key) => {
      if (originalData[files[key].field_name] !== undefined) {
        originalData[files[key].field_name] = files[key].value;
      }
    });

    const response = await fetch(`/api/forms/submit`, {
      method: "POST",
      body: JSON.stringify({
        webform_id: id,
        ...originalData,
      }),
    });

    if (response.ok) {
      setStatus("success");
      setMessage(
        "Votre message a été transmis à notre équipe. <br>Il sera traité dans les plus brefs délais."
      );
      return true;
    }

    setStatus("error");
    setMessage(
      "Une erreur est survenue lors de la soumission du formulaire. <br>Merci de contacter un administrateur ou de réessayer plus tard."
    );
    return false;
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      {required && (
        <div className="mb-8 text-3xl font-semibold">
          Les champs marqu&eacute;s d&apos;un ast&eacute;risque (*) sont
          obligatoires
        </div>
      )}

      {fields.map((field) => {
        const type = field["#type"];
        if (Object.prototype.hasOwnProperty.call(components, type)) {
          const FormField = components[type];
          return (
            <div key={field.id} className="mb-8">
              <FormField
                register={register}
                control={control}
                errors={errors}
                field={field}
                filesHandler={filesHandler}
                requiredHandler={setRequired}
              />
            </div>
          );
        }

        console.warn(`Unhandled form field type "${type}".`);
        return undefined;
      })}

      {captcha && <Captcha ref={captchaRef} field={captcha} />}

      <div className="flex flex-col md:flex-row md:items-center">
        <div className="flex flex-row items-center space-x-8">
          <Button theme="white" inverse disabled={status === "submitting"}>
            Envoyer
          </Button>

          {status === "submitting" && (
            <svg
              className="animate-spin h-6 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden={status !== "submitting"}
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
        </div>

        {message !== "" && (
          <div
            className={classNames(
              status === "success" && "text-green-500",
              status === "error" && "text-red-500",
              "text-sm mt-8 md:mt-0 md:ml-8"
            )}
          >
            <Body value={message} />
          </div>
        )}
      </div>
    </form>
  );
}
