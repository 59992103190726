import React from "react";
import Paragraphs from "components/Paragraphs";
import Body from "components/Body";

export default function NodePageContent({ node, ...props }) {
  return (
    <article {...props}>
      <div>
        {node.body?.processed && (
          <div className="prose">
            <Body value={node.body?.processed} />
          </div>
        )}
      </div>

      {node.field_paragraphs?.length > 0 && (
        <Paragraphs paragraphs={node.field_paragraphs} />
      )}
    </article>
  );
}
