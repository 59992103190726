import * as React from "react";
import {
  getPathsFromContext,
  getResourceCollectionFromContext,
  getResourceFromContext,
} from "next-drupal";
import NodePageContent from "components/Page/NodePageContent";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import getCrumbs from "lib/getCrumbs";
import { getGlobals } from "lib/getGlobals";
import Header from "components/Header";
import Meta from "components/Meta";
import { getNodeChilds } from "lib/getMenuItem";
import NodePageMenuItem from "components/Page/NodePageMenuItem";
import getForm from "lib/getForm";
import Form from "components/Form";
import Image from "next/image";

export default function NodePage({
  crumbs,
  globals,
  node,
  form,
  newses,
  partners,
}) {
  if (!node) return null;
  const childs = getNodeChilds(node.title, globals.menus.crumbsFallback);

  return (
    <Layout globals={globals} newses={newses} partners={partners}>
      <Meta title={node.title} description={node.body?.summary} />
      <Header crumbs={crumbs} title={node.title} />
      <section className="pt-10 pb-48 overflow-hidden relative">
        <div
          className="absolute -left-60 top-1/2 -translate-y-1/4 scale-75 opacity-20"
          style={{
            width: "30vw",
            height: "30vw",
          }}
        >
          <Image
            src="/images/decorations/lightblue-martinique.svg"
            height={571}
            width={490}
            alt="Réprésentation de la Martinique"
          />
        </div>
        <div
          className="absolute -right-40 top-0 opacity-20"
          style={{
            width: "30vw",
            height: "30vw",
          }}
        >
          <Image
            src="/images/decorations/lightblue-mayotte.svg"
            height={571}
            width={490}
            alt="Réprésentation de mayotte"
          />
        </div>
        <div className="container mx-auto px-4 lg:max-w-screen-lg">
          <NodePageContent node={node} />
        </div>

        {form && (
          <section className="py-10">
            <div className="container mx-auto px-4 lg:max-w-screen-lg">
              <Form id={form.id} dataFields={form.fields} />
            </div>
          </section>
        )}
        {childs.length > 0 && (
          <section className="py-10">
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
                {childs.map((item, index) => {
                  return (
                    <div key={index}>
                      <NodePageMenuItem item={item} />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}
      </section>
    </Layout>
  );
}

export async function getStaticPaths(context) {
  return {
    paths: await getPathsFromContext(["node--page"], context),
    fallback: true,
  };
}

export async function getStaticProps(context) {
  const globals = await getGlobals(context);
  const params = new DrupalJsonApiParams();
  const newsesParams = new DrupalJsonApiParams();
  const partnersParams = new DrupalJsonApiParams();

  const node = await getResourceFromContext("node--page", context, {
    params: params
      .addInclude([
        "field_paragraphs",
        "field_paragraphs.field_image",
        "field_paragraphs.field_files",
        "field_paragraphs.field_paragraphs",
        "field_paragraphs.field_paragraphs.field_image",
        "field_form",
      ])
      .getQueryObject(),
  });

  newsesParams
    .addInclude(["field_image", "field_type"])
    .addFilter("status", 1)
    .addSort("created", "DESC")
    .addSort("title", "ASC")
    .addPageLimit(3);

  partnersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("title", "ASC");

  const newses = await getResourceCollectionFromContext("node--news", context, {
    params: newsesParams.getQueryObject(),
  });

  const partners = await getResourceCollectionFromContext(
    "node--partners",
    context,
    {
      params: partnersParams.getQueryObject(),
    }
  );

  if (!node || (!context.preview && !node?.status)) {
    return {
      notFound: true,
      revalidate: 30,
    };
  }

  return {
    props: {
      crumbs: getCrumbs(context, node, globals.menus.crumbsFallback),
      globals,
      newses,
      partners,
      node,
      form: node.field_form
        ? await getForm(
            node.field_form.resourceIdObjMeta.drupal_internal__target_id
          )
        : null,
    },
    revalidate: 30,
  };
}
