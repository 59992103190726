import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function Text({ register, errors, field }) {
  const { id, name, disabled, required, titleDisplay, requiredMessage } =
    useField(field);
  const hasError = errors[name] || false;

  const titleDisplayCases = {
    none: "hidden",
    invisible: "sr-only",
    after: "",
    before: "",
    inline: "",
  };

  return (
    <>
      <label
        htmlFor={id}
        className={classNames(
          hasError ? "text-red-500" : "text-primary-dark",
          titleDisplayCases[titleDisplay],
          "inline-block text-3xl font-semibold mb-2"
        )}
      >
        {field["#title"]}
        {required && " *"}
      </label>

      {hasError && (
        <p className="text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}

      <input
        id={id}
        name={name}
        type={field["#type"] === "textfield" ? "text" : field["#type"]}
        {...register(name, {
          required: required && requiredMessage,
          disabled,
        })}
        placeholder={field["#placeholder"]}
        className={classNames(
          hasError ? "border-red-500" : "border-secondary",
          "w-full border-2 py-2 px-4 placeholder:text-gray-300"
        )}
      />
    </>
  );
}
