import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function File({ register, errors, field, filesHandler }) {
  const { formId, id, disabled, required, requiredMessage, name } =
    useField(field);
  const hasError = errors[name] || false;

  async function getApiFileToken(file) {
    const response = await fetch(
      `/api/forms/file-upload?webform_id=${formId}&name=${name}&filename=${file.name}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
          "Content-Disposition": `file; filename="${file.name}"`,
        },
        body: file,
      }
    );

    const data = await response.json();

    // Add file data to webform state to be used in submit
    filesHandler(name, data);
  }

  return (
    <>
      <label
        htmlFor={id}
        className={classNames(
          hasError ? "text-red-500" : "text-primary-dark",
          "inline-block text-3xl font-semibold mb-2"
        )}
      >
        {field["#title"]}
        {required && " *"}
      </label>

      {hasError && (
        <p className="text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}

      <input
        id={id}
        type="file"
        {...register(name, {
          required: required && requiredMessage,
          disabled,
          onChange: (e) => {
            Object.keys(e.target.files).forEach((key) => {
              getApiFileToken(e.target.files[key]);
            });
          },
        })}
        className={classNames(
          hasError ? "border-red-500" : "border-secondary",
          "w-full border-2 px-4 py-2 placeholder:text-gray-300"
        )}
      />
    </>
  );
}
