import React from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { useRouter } from "next/router";

export default function NodePageMenuItem({ item }) {
  const router = useRouter();

  const clickHandler = () => {
    router.push(item.url);
  };

  return (
    <div
      className="group relative h-full p-10 pb-24 bg-tertiary-light border-l-2 border-white transition cursor-pointer group-hover:border-black"
      onClick={() => clickHandler()}
    >
      <div className="text-2xl font-serif font-semibold">{item.title}</div>
      <Link href={item.url} passHref>
        <a className="absolute bottom-0 right-0 bg-white group-hover:bg-black text-white order-4 py-4 px-8">
          <ArrowRightIcon className="h-5 w-5" />
          <span className="sr-only">Voir tout</span>
        </a>
      </Link>
    </div>
  );
}
