import React from "react";
import { RadioGroup } from "@headlessui/react";
import { Controller } from "react-hook-form";
import classNames from "lib/classNames";
import { CheckIcon } from "@heroicons/react/outline";
import useField from "lib/useField";

export default function Radios({ control, errors, field }) {
  const { name, disabled, required, requiredMessage, options } =
    useField(field);
  const hasError = errors[name] || false;

  if (options === null) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && requiredMessage,
        disabled,
      }}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} onChange={onChange}>
          <RadioGroup.Label
            className={classNames(
              hasError ? "text-red-500" : "text-primary-dark",
              "inline-block text-3xl font-semibold mb-2"
            )}
          >
            {field["#title"]}
            {required && " *"}
          </RadioGroup.Label>

          {hasError && (
            <p className="text-sm text-red-500 mb-2 font-semibold">
              {errors[name].message}
            </p>
          )}

          <div className="flex flex-wrap flex-col lg:flex-row lg:items-center justify-center lg:justify-start lg:space-x-8">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.id}
                value={option.value}
                className="mt-1 cursor-pointer"
              >
                {({ checked }) => (
                  <div
                    className={classNames(
                      checked ? "text-secondary" : "text-primary-dark",
                      "flex items-center space-x-2"
                    )}
                  >
                    {checked ? (
                      <span className="flex items-center justify-center h-5 w-5 bg-white border-2 border-secondary rounded-full">
                        <CheckIcon className="text-secondary" />
                        <span className="sr-only">Coché</span>
                      </span>
                    ) : (
                      <span className="flex items-center justify-center h-5 w-5 bg-transparent border-2 border-secondary rounded-full">
                        <span className="sr-only">Non Coché</span>
                      </span>
                    )}
                    <span>{option.key}</span>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
    />
  );
}
