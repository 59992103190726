import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function Checkboxes({ register, errors, field }) {
  const { name, disabled, required, requiredMessage, options } =
    useField(field);
  const hasError = errors[name] || false;

  if (options === null) {
    return null;
  }

  return (
    <>
      <label
        className={classNames(
          hasError ? "text-red-500" : "text-primary-dark",
          "inline-block text-3xl font-semibold mb-2"
        )}
        role="none"
        htmlFor="options"
      >
        {field["#title"]}
        {required && " *"}
      </label>

      {hasError && (
        <p className="text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}

      <div className="flex flex-wrap flex-col justify-center">
        {options.map((option) => (
          <div key={option.id} className="mt-1">
            <label
              className="flex items-center space-x-2 cursor-pointer"
              htmlFor="options"
            >
              <input
                id={option.key}
                name={option.key}
                value={option.value}
                type="checkbox"
                className="h-4 w-4 rounded border border-secondary text-secondary focus:ring-secondary"
                {...register(name, {
                  required: required && requiredMessage,
                  disabled,
                })}
              />
              <span>{option.key}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
